import React from "react";
import PropTypes from "prop-types";
import styles from "./ShowCase.module.scss";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Grid2,
  Grow,
  Box,
} from "@mui/material";

// Showcase Card with flashy effects
const ShowcaseCard = ({ imageUrl, title, description, buttonText }) => {
  return (
    <Grow in timeout={800}>
      <Box
        sx={{
          perspective: "1000px",
          margin: 2,
        }}
      >
        <Card
          sx={{
            maxWidth: 345,
            borderRadius: 2,
            boxShadow: "0px 15px 25px rgba(0, 0, 0, 0.3)",
            transition: "transform 0.6s ease-in-out, box-shadow 0.4s",
            transformStyle: "preserve-3d",
            "&:hover": {
              transform: "rotateY(5deg) rotateX(20deg)",

              boxShadow: "0px 20px 40px rgba(0, 0, 0, 0.5)",
            },

            background: "rgba(255, 255, 255, 0.1)",
            backdropFilter: "blur(30px)", // Glassmorphism effect
            border: "1px solid rgba(255, 255, 255, 0.2)",
          }}
        >
          <CardMedia
            component="img"
            height="200"
            image={imageUrl}
            alt={title}
            sx={{
              transition: "transform 0.5s ease-in-out",
              "&:hover": {
                transform: "scale(1.1)",
              },
            }}
          />
          <CardContent sx={{ textAlign: "center", color: "#fff" }}>
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              sx={{ fontWeight: "bold" }}
            >
              {title}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              sx={{ marginBottom: 2, color: "#fff" }}
            >
              {description}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{
                marginTop: 2,
                background: "linear-gradient(45deg, #663399 30%, #000 90%)",
              }}
            >
              {buttonText}
            </Button>
          </CardContent>
        </Card>
      </Box>
    </Grow>
  );
};

// Grid2 layout with fancy effects
const ShowcaseGrid = ({ items }) => {
  return (
    <Grid2 container spacing={4} justifyContent="center">
      {items.map((item, index) => (
        <Grid2 item key={index}>
          <ShowcaseCard
            imageUrl={item.imageUrl}
            title={item.title}
            description={item.description}
            buttonText={item.buttonText}
          />
        </Grid2>
      ))}
    </Grid2>
  );
};

const showcaseItems = [
  {
    imageUrl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjy7tSiCdkGwhuPGHi20EbxVNaAZOiZrTajA&s",
    title: "Systems Architecture",
    description: "This is an amazing product that you should try.",
    buttonText: "Learn More",
  },
  {
    imageUrl:
      "https://img.freepik.com/premium-photo/cardano-blockchain-platform_23-2150411956.jpg",
    title: "Networking",
    description: "Experience the best features of this product.",
    buttonText: "Check it out",
  },
  {
    imageUrl:
      "https://www.mckinsey.com/spContent/bespoke/tech-trends-2024-hero-nav/techtrends-hero-desktop.jpg",
    title: "Ai",
    description: "Discover the future with our innovative solutions.",
    buttonText: "Explore",
  },
];

const ShowCase = () => {
  return (
    <div
      className={styles.ShowCase}
      style={{
        padding: "2rem",
        backgroundColor: "rgba(0,0,0,0.8)",
      }}
    >
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{
          color: "#fff",
          textTransform: "uppercase",
          letterSpacing: "0.1em",
          fontWeight: "bold",
        }}
      >
        Let's talk
      </Typography>
      <ShowcaseGrid items={showcaseItems} />
    </div>
  );
};

ShowCase.propTypes = {};

ShowCase.defaultProps = {};

export default ShowCase;
