import React from "react";
import PropTypes from "prop-types";
import styles from "./Parallax.module.scss";

const Parallax = () => (
  <div className={styles.ParallaxContainer}>
    <div className={styles.ParallaxText}>
      <h1>Explore the Wonders of the World</h1>
      <p>Join me on a journey of discovery and adventure!</p>
    </div>
  </div>
);

Parallax.propTypes = {};

Parallax.defaultProps = {};

export default Parallax;
