import React, { useEffect, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadFull } from "tsparticles";
import logo from "./logo.svg";
import "./App.css";
import particlesOptions from "./particles.json";
import HeroText from "./components/HeroText/HeroText";
import Parallax from "./components/Parallax/Parallax";
import About from "./components/About/About";
import ShowCase from "./components/ShowCase/ShowCase";

function App() {
  const [init, setInit] = useState(false);

  useEffect(() => {
    if (init) {
      return;
    }

    initParticlesEngine(async (engine) => {
      await loadFull(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  return (
    <div className="App">
      {init && <Particles options={particlesOptions} />}
      <div>
        {/* 
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <p>
            Edit <code>src/particles.json</code> to customize Particles, then
            save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
          <a
            className="App-link"
            href="https://particles.js.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            See Particles samples
          </a>
        </header>*/}
        <HeroText />
        <Parallax />
      </div>

      <div>
        <About />
      </div>
      <ShowCase />
    </div>
  );
}

export default App;
