import React from "react";
import PropTypes from "prop-types";
import styles from "./About.module.scss";
import { Grid2, Typography } from "@mui/material";
const About = () => (
  <div className={styles.About}>
    <Grid2
      container
      spacing={2}
      sx={{
        marghin: "20px",
        padding: 5,
        //background: "rgba(0, 0, 0, 0.9)"
      }}
    >
      <Grid2
        size={12}
        sx={{
          marghin: "20px",
          padding: 5,
          background: "rgba(6, 6, 0, 0.6)",
          borderRadius: 2,
        }}
      >
        <Typography variant="h4" gutterBottom>
          MSce Sergio Morales Reyes About Me
        </Typography>

        <Typography className={styles["title"]} variant="body1" gutterBottom>
          Master's in Artificial Intelligence For Business Transformation
        </Typography>
        <Typography variant="body1" gutterBottom>
          Universitat Politècnica de Catalunya · Barcelona Tech - UPC
        </Typography>
        <Typography className={styles["title"]} variant="body1" gutterBottom>
          Bachellor's Computer Systems Engineering
          <br />
          Bachiller Ingenieria en Sistemas Computacionales
        </Typography>
        <Typography variant="body1" gutterBottom>
          Universidad Latina de Costa Rica
        </Typography>
      </Grid2>
      <Grid2
        size={4}
        sx={{
          background: 'url("/images/class.jpeg")',
          backgroundSize: "cover",
        }}
      ></Grid2>
      <Grid2 size={8} sx={{ padding: "20px" }}>
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            maxWidth: "680px",
            margin: "auto",
            fontSize: "0.8em",
            fontFamily: "Righteous",
          }}
        >
          As a seasoned Software Engineer, Technology Director, and Principal
          Engineer, I've accumulated over a decade of experience in the tech
          industry. My academic journey includes a Master's in Artificial
          Intelligence and another in Business Transformation. Additionally,
          I've had the honor of serving as a Professor of Programming Languages
          and Web Programming. My passion revolves around crafting efficient
          software solutions, optimizing performance, and staying abreast of
          cutting-edge technology trends. Blog Linked In Github
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            maxWidth: "680px",
            margin: "auto",
            marginTop: "20px",
            fontSize: "0.8em",
          }}
        >
          Como experimentado Ingeniero de Software, Director de Tecnología y
          Ingeniero Principal, he acumulado más de una década de experiencia en
          la industria tecnológica. Mi trayectoria académica incluye una
          Maestría en Inteligencia Artificial y otra en Transformación
          Empresarial. Además, he tenido el honor de desempeñarme como Profesor
          de Lenguajes de Programación y Programación Web. Mi pasión se centra
          en desarrollar soluciones de software eficientes, optimizar el
          rendimiento y mantenerme al tanto de las últimas tendencias
          tecnológicas. Blog Linked In Github
        </Typography>
      </Grid2>
    </Grid2>
  </div>
);

About.propTypes = {};

About.defaultProps = {};

export default About;
