import React from "react";
import PropTypes from "prop-types";
import styles from "./HeroText.module.scss";
import { TypeAnimation } from "react-type-animation";

const HeroText = () => (
  <div className={styles.HeroText}>
    <TypeAnimation
      style={{ whiteSpace: "pre-line" }}
      sequence={[
        // Same substring at the start will only be typed once, initially
        `Welcome to my personal corner of the web,  \n where technology and creativity come together! \n` +
          //  `As a passionate software engineer, \n` +
          //  `I love building solutions that empower users and push \n` +
          //  `the boundaries of what's possible. \n` +
          //`Whether I'm diving into code, exploring emerging tech, or sharing insights from my latest projects, \n` +
          //  `I'm always excited to learn and grow. \n` +
          `Feel free to explore, get inspired, and connect with me on this journey!`,
      ]}
      speed={20}
      //style={{ fontSize: "2em" }}
      // repeat={Infinity}
    />
  </div>
);

HeroText.propTypes = {};

HeroText.defaultProps = {};

export default HeroText;
